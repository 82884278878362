/* eslint-disable no-irregular-whitespace */
// noinspection NonAsciiCharacters

// "　" 引号里是CJK空白字符(\u3000)，可以复制使用，用于两字人名的对齐

function state() {
    const info_zh = {
        "教授": [
            {
                name: "高跃",
                img: require('../assets/yue.jpg'),
                description: `长聘副教授, 博士生导师<br>
            清华大学, 软件学院`
            },
        ],
        "博士后": [
            { name: "韩向敏", img: require('../assets/people/XiangminHan.jpg'), description: "医学超图计算" },
            { name: "李思奇", img: require('../assets/people/SiqiLi.jpg'), description: "视觉超图计算" },
        ],
        "博士生": [
            // { name: "张宇博", img: require('../assets/people/YuboZhang.jpg'), description: "2017级博士生" },
            { name: "徐　阳", img: require('../assets/people/YangXu.jpg'), description: "2018级博士生" },
            // { name: "吉书仪", img: require('../assets/people/ShuyiJi.jpg'), description: "2019级博士生" },
            // { name: "李思奇", img: require('../assets/people/SiqiLi.jpg'), description: "2019级博士生" },
            // { name: "林　航", img: require('../assets/people/HangLin.jpg'), description: "2020级博士生" },
            // { name: "李仁杰", img: require('../assets/people/RenjieLi.jpg'), description: "2020级博士生" },
            { name: "别　林", img: require('../assets/people/LinBie.jpg'), description: "2020级博士生" },
            { name: "丰一帆", img: require('../assets/people/YifanFeng.jpg'), description: "2021级博士生", href: "https://fengyifan.site/" },
            { name: "赵乙宁", img: require('../assets/people/YiningZhao.jpg'), description: "2022级博士生" },
            { name: "颜杰龙", img: require('../assets/people/JielongYan.jpg'), description: "2023级博士生" },
        ],
        "硕士生": [
            { name: "周致宽", img: require('../assets/people/周致宽.jpg'), description: "2022级硕士生" },
            { name: "顾霆枫", img: require('../assets/people/TingfengGu.jpg'), description: "2023级硕士生" },
        ],
        "访问学者": [
            // { name: "王博华", img: require('../assets/people/王博华.jpg') },
            // { name: "方　楼", img: require('../assets/people/方楼.jpg') },
            // { name: "黄健刚", img: require('../assets/people/黄健刚.jpg') },
            // { name: "王跃航", img: require('../assets/people/王悦航.jpg') },
            // { name: "韩佳澍", img: require('../assets/people/韩佳澍.jpeg') },
            // { name: "江雨彤", img: require('../assets/people/江雨彤.jpeg') },
            // { name: "金　涛", img: require('../assets/people/金涛.jpeg') },
            // { name: "李　智", img: require('../assets/people/李智.jpeg') },
            // { name: "唐青梅", img: require('../assets/people/唐青梅.jpeg') },
            // { name: "吴　旭", img: require('../assets/people/吴旭.jpeg') },
            // { name: "张伊凡", img: require('../assets/people/张伊凡.jpeg') },
            // { name: "钟　昊", img: require('../assets/people/钟昊.jpeg') },
            { name: "黄　侃", img: require('../assets/people/黄侃.jpg') },
        ],
        "本科生": [
            // { name: "陈亦非", img: require('../assets/people/YifeiChen.jpg') },
            { name: "潘首安", img: require('../assets/people/ShouanPan.jpg') },
            { name: "穆俊成", img: require('../assets/people/JunchengMu.jpg') },
            { name: "罗一鹤", img: require('../assets/people/YiheLuo.jpg') },
        ],
        "校友": [
            { name: "曹智林", subtitle: "(2016-2018)", description: "现就读于卡内基·梅隆大学 (Carnegie Mellon University)" },
            { name: "周恒光", subtitle: "(2018-2018)", description: "现就读于多伦多大学 (University of Toronto)" },
            { name: "杨　敏", subtitle: "(2018-2019)", description: "现就读于多伦多大学 (University of Toronto)" },
            { name: "有昊轩", subtitle: "(2018-2019)", description: "现就读于哥伦比亚大学 (Columbia University)" },
            // { name: "王荣广", subtitle: "(2019-2019)", description: "现就读于宾夕法尼亚大学 (University of Pennsylvania)" },
            { name: "蒋建文", subtitle: "(2017-2020)", description: "现工作于阿里巴巴达摩院" },
            { name: "龚　镖", subtitle: "(2017-2020)", description: "现工作于阿里巴巴达摩院" },
            { name: "林浩杰", subtitle: "(2018-2021)", description: "现工作于易方达基金管理有限公司 (E Fund Management Co., Ltd)" },
            { name: "陈自强", subtitle: "(2018-2021)", description: "现工作于高盛（亚洲）有限责任公司 (Goldman Sachs (Asia) L.L.C.)" },
            { name: "黄正跃", subtitle: "(2018-2021)", description: "现工作于腾讯视频" },
            { name: "从业臻", subtitle: "(2019-2021)", description: "现就读于斯坦福大学 (Stanford University)" },
            { name: "冯玉彤", subtitle: "(2019-2022)", description: "现工作于阿里巴巴达摩院" },
            { name: "张宇博", subtitle: "(2017-2024)", description: "现工作于华为" },
            { name: "张子昭", subtitle: "(2018-2023)", description: "现于康奈尔大学 (Cornell University) 从事博士后研究工作" },
            { name: "吉书仪", subtitle: "(2019-2024)", description: "现工作于华为" },
            { name: "黄　侃", subtitle: "(2022-2023)", description: "现工作于上海海事大学信息工程学院" },
            { name: "卢嘉轩", subtitle: "(2020-2023)", description: "现工作于上海人工智能实验室"  },
            { name: "程嘉梁", subtitle: "(2020-2023)", description: "现工作于蚂蚁金服"  },
            { name: "魏宇轩", subtitle: "(2019-2022)", description: "" }, // 公务员考虑一下是否显示
            { name: "包　迪", subtitle: "(2017-2020)", description: "" },
            { name: "戴　岳", subtitle: "(2020-2023)", description: ""  },
            // { name: "李胜锐", subtitle: "" },
            { name: "刘欣爽", subtitle: "" },
            // { name: "陈亦非", subtitle: "" },
        ],
    }
    const info_en = {
        "教授": [
            {
                name: "Yue Gao",
                img: require('../assets/yue.jpg'),
                description: `Associate Professor<br> Doctoral Supervisor<br>
            Tsinghua University<br> School of Software`
            },
        ],
        "博士后": [
            { name: "Xiangmin Han", img: require('../assets/people/XiangminHan.jpg'), description: "Medical Hypergraph Computation" },
            { name: "Siqi Li", img: require('../assets/people/SiqiLi.jpg'), description: "Visual Hypergraph Computation" },
        ],
        "博士生": [
            // { name: "Yubo Zhang", img: require('../assets/people/YuboZhang.jpg'), description: "2017 Ph.D" },
            { name: "Yang Xu", img: require('../assets/people/YangXu.jpg'), description: "2018 Ph.D" },
            // { name: "Shuyi Ji", img: require('../assets/people/ShuyiJi.jpg'), description: "2019 Ph.D" },
            
            // { name: "Hang Lin", img: require('../assets/people/HangLin.jpg'), description: "2020 Ph.D" },
            // { name: "Renjie Li", img: require('../assets/people/RenjieLi.jpg'), description: "2020 Ph.D" },
            { name: "Lin Bie", img: require('../assets/people/LinBie.jpg'), description: "2020 Ph.D" },
            { name: "Yifan Feng", img: require('../assets/people/YifanFeng.jpg'), description: "2021 Ph.D", href: "https://fengyifan.site/" },
            { name: "Yining Zhao", img: require('../assets/people/YiningZhao.jpg'), description: "2022 Ph.D" },
            { name: "Jielong Yan", img: require('../assets/people/JielongYan.jpg'), description: "2023 Ph.D" },
        ],
        "硕士生": [
            { name: "Zhikuan Zhou", img: require('../assets/people/周致宽.jpg'), description: "2022 master" },
            { name: "Tingfeng Gu", img: require('../assets/people/TingfengGu.jpg'), description: "2023 master" },
        ],
        "访问学者": [
            // { name: "Bohua Wang", img: require('../assets/people/王博华.jpg') },
            // { name: "Lou Fang", img: require('../assets/people/方楼.jpg') },
            // { name: "Jiangang Huang", img: require('../assets/people/黄健刚.jpg') },
            // { name: "Yuehang Wang", img: require('../assets/people/王悦航.jpg') },
            // { name: "Jiashu Han", img: require('../assets/people/韩佳澍.jpeg') },
            // { name: "Yutong Jiang", img: require('../assets/people/江雨彤.jpeg') },
            // { name: "Tao Jin", img: require('../assets/people/金涛.jpeg') },
            // { name: "Zhi Li", img: require('../assets/people/李智.jpeg') },
            // { name: "Qingmei Tang", img: require('../assets/people/唐青梅.jpeg') },
            // { name: "Xu Wu", img: require('../assets/people/吴旭.jpeg') },
            // { name: "Yifan Zhang", img: require('../assets/people/张伊凡.jpeg') },
            // { name: "Hao Zhong", img: require('../assets/people/钟昊.jpeg') },
            { name: "Kan Huang", img: require('../assets/people/黄侃.jpg') },
        ],
        "本科生": [
            // { name: "Yifei Chen", img: require('../assets/people/YifeiChen.jpg') },
            { name: "Shouan Pan", img: require('../assets/people/ShouanPan.jpg') },
            { name: "Juncheng Mu", img: require('../assets/people/JunchengMu.jpg') },
            { name: "Yihe Luo", img: require('../assets/people/YiheLuo.jpg') },
        ],
        "校友": [
            { name: "Zhilin Cao", subtitle: "(2016-2018)", description: "Currently studying at Carnegie Mellon University" },
            { name: "Hengguang Zhou", subtitle: "(2018-2018)", description: "Currently studying at University of Toronto" },
            { name: "Min Yang", subtitle: "(2018-2019)", description: "Currently studying at University of Toronto" },
            { name: "Haoxuan You", subtitle: "(2018-2019)", description: "Currently studying at Columbia University" },
            // { name: "Rongguang Wang", subtitle: "(2019-2019)", description: "Currently studying at University of Pennsylvania" },
            { name: "Jianwen Jiang", subtitle: "(2017-2020)", description: "Currently working at Alibaba DAMO Academy" },
            { name: "Biao Gong", subtitle: "(2017-2020)", description: "Currently working at Alibaba DAMO Academy" },
            { name: "Haojie Lin", subtitle: "(2018-2021)", description: "Currently working at E Fund Management Co., Ltd" },
            { name: "Ziqiang Chen", subtitle: "(2018-2021)", description: "Currently working at Goldman Sachs (Asia) L.L.C." },
            { name: "Zhengyue Huang", subtitle: "(2018-2021)", description: "Currently working at Tencent Video" },
            { name: "Yezhen Cong", subtitle: "(2019-2021)", description: "Currently studying at Stanford University" },
            { name: "Yutong Feng", subtitle: "(2019-2022)", description: "Currently working at Alibaba DAMO Academy" },
            { name: "Yutong Feng", subtitle: "(2019-2022)", description: "Currently working at Alibaba DAMO Academy" },
            { name: "Yubo Zhang", subtitle: "(2017-2024)", description: "Currently working at Huawei" },
            { name: "Zizhao Zhang", subtitle: "(2018-2023)", description: "Currently working as a postdoctoral researcher at Cornell University" },
            { name: "Shuyi Ji", subtitle: "(2019-2024)", description: "Currently working at Huawei" },
            { name: "Kai Huang", subtitle: "(2022-2023)", description: "Currently working at Shanghai Maritime University" },
            { name: "Jiaxuan Lu", subtitle: "(2020-2023)", description: "Currently working at Shanghai AI Lab" },
            { name: "Zhikuan Zhou", subtitle: "(2020-2023)", description: "Currently working at Ant Group " },
            { name: "Di Bao", subtitle: "(2017-2020)", description: "" },
            { name: "Yuxuan Wei", subtitle: "(2019-2022)", description: "" },
            { name: "Yue Dai", subtitle: "(2020-2023)", description: "" },
            // { name: "Shengrui Li", subtitle: "" },
            { name: "Xinshuang Liu", subtitle: "" },
            // { name: "Yifei Chen", subtitle: "" },
        ],
    }
    return { zh: info_zh, en: info_en }
}

export default state()

export type People = ReturnType<typeof state>["zh"]["教授"] | ReturnType<typeof state>["zh"]["博士后"] | ReturnType<typeof state>["zh"]["访问学者"] | ReturnType<typeof state>["zh"]["博士生"] | ReturnType<typeof state>["zh"]["硕士生"] | ReturnType<typeof state>["zh"]["本科生"] | ReturnType<typeof state>["zh"]["校友"]